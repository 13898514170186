import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { Switch, Route, Redirect } from 'react-router'
import Amplify from '@aws-amplify/auth'
import Typography from '@material-ui/core/Typography'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import GridOnIcon from '@material-ui/icons/GridOn'
import NotificationsIcon from '@material-ui/icons/Notifications'
import ImageSearchIcon from '@material-ui/icons/ImageSearch'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import Container from '@material-ui/core/Container'

import AppBar from '@material-ui/core/AppBar'

import { Session } from './context'
import { History } from './system/history'

import Auth from './apps/Auth'
import User from './apps/User'
import Collection from './apps/Collection'
import { ErrorPage } from './pages/Error'

import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

import AccountCircle from '@material-ui/icons/AccountCircle'

const Headline = styled(Typography)`
  flex-grow: 1;
`

const DrawerIcon = styled(ListItemIcon)`
  min-width: 40px;
`

const DrawerContent = styled.div`
  width: 220px;
`

const BottomList = styled(List)`
  padding-bottom: 0px;
`

const BottonLink = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0px;
`

const Page = styled.div`
  padding-top: 20px;
`

const App: React.FC = () => {
  const [session, setSession] = useState<Session | undefined>()
  const [sidebar, setSidebar] = useState(false)

  const doLogout = useCallback(() => {
    Amplify.signOut({ global: true })
    setSession(undefined)

    History.push('/auth')
  }, [])

  const showSidebar = useCallback(() => {
    setSidebar(true)
  }, [setSidebar])

  const hideSidebar = useCallback(() => {
    setSidebar(false)
  }, [setSidebar])

  useEffect(() => {
    Amplify.currentSession().then(async () => {
      const user = await Amplify.currentAuthenticatedUser()

      console.log(user)

      setSession({ username: user.username })
    })
  }, [setSession])

  return (
    <Session.Provider value={{ session, setSession }}>
      <Helmet />
      <Switch>
        <Route path="/" exact>
          <Redirect to="/auth" />
        </Route>
        <Route path="/auth" component={Auth} />

        {session ? (
          <>
            <AppBar position="static" color="primary">
              <Drawer open={sidebar} onClose={() => hideSidebar()}>
                <DrawerContent>
                  <List>
                    <ListItem button onClick={() => History.push('/dashboard')}>
                      <DrawerIcon>
                        <GridOnIcon />
                      </DrawerIcon>
                      <ListItemText primary="Dashboard" secondary={session.username} />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => History.push('/inbox')}>
                      <DrawerIcon>
                        <InboxIcon />
                      </DrawerIcon>
                      <ListItemText primary="Inbox" />
                    </ListItem>
                    <ListItem button onClick={() => History.push('/notifications')}>
                      <DrawerIcon>
                        <NotificationsIcon />
                      </DrawerIcon>
                      <ListItemText primary="Notifications" />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => History.push('/media')}>
                      <DrawerIcon>
                        <ImageSearchIcon />
                      </DrawerIcon>
                      <ListItemText primary="Media" />
                    </ListItem>
                  </List>

                  <BottonLink>
                    <BottomList>
                      <Divider />
                      <ListItem button onClick={() => doLogout()}>
                        <DrawerIcon>
                          <PowerSettingsNewIcon />
                        </DrawerIcon>
                        <ListItemText primary="Sign Out" />
                      </ListItem>
                    </BottomList>
                  </BottonLink>
                </DrawerContent>
              </Drawer>

              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => showSidebar()}
                >
                  <MenuIcon />
                </IconButton>
                <Headline variant="h6">KINKS.club</Headline>

                <div>
                  <IconButton
                    edge="end"
                    onClick={() => History.push(`/user/${session.username}`)}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                </div>
              </Toolbar>
            </AppBar>

            <Page>
              <Container maxWidth="md">
                <Route component={User} path="/user/:username" />
                <Route component={Collection} path="/collection/:collection" />
              </Container>
            </Page>
          </>
        ) : null}
        <Route component={ErrorPage} />
      </Switch>
    </Session.Provider>
  )
}

export default App
