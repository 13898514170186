import React from 'react'
import { Switch, Route } from 'react-router'

import { Login } from './Login'
import { Register } from './Register'

export default () => (
  <Switch>
    <Route path="/auth" exact component={Login} />
    <Route path="/auth/register/:invite?" component={Register} />
  </Switch>
)
