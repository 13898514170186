import React from 'react'
import { withRouter } from 'react-router'
import { useQuery } from '@apollo/react-hooks'

import Card from '@material-ui/core/Card'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'

import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import { CollectionByID } from '../../queries'

export default withRouter(({ match }) => {
  const collection = match.params['collection']

  const { data, error, loading } = useQuery(CollectionByID, {
    variables: { id: collection },
  })

  if (loading) {
    return <p>Loading …</p>
  }

  if (error) {
    console.log(error)
    return <p>Error …</p>
  }

  return (
    <>
      <Paper elevation={1}>
        <Card variant="outlined">
          <CardContent>
            <Avatar src={data.collection.cover.url} />

            <Typography color="primary" gutterBottom>
              {data.collection.name}
            </Typography>

            <Typography variant="body2" component="p">
              Created at {data.collection.createdAt}
            </Typography>
          </CardContent>
        </Card>
      </Paper>
      <br />
      <Paper elevation={1}>
        <Card>
          <CardContent>
            <Typography color="primary" gutterBottom>
              {data.collection.items.list.length} Items
              {data.collection.items.list.map((item: any) => {
                return <img src={item.media.url} alt="Foo" style={{ width: '100px' }} />
              })}
            </Typography>
          </CardContent>
        </Card>
      </Paper>
    </>
  )
})
