import styled from 'styled-components'
import { Link as RouteLink } from 'react-router-dom'

export const Link = styled(RouteLink)`
  text-decoration: none;

  &,
  &:active,
  &:visited {
    color: #3f51b5;
  }

  &:hover {
    text-decoration: underline;
    color: #303f9f;
  }
`
