import { gql } from 'apollo-boost'

export const UserByID = gql`
  query userByID($id: ID!) {
    user(id: $id) {
      id
      name
      createdAt
      avatar {
        url
      }
    }
  }
`

export const CollectionByID = gql`
  query collectionByID($id: ID!) {
    collection(id: $id) {
      id
      name
      description
      createdAt
      cover {
        url
      }

      items {
        list {
          id
          media {
            id
            ... on MediaItemImage {
              url
            }
          }
        }
        next
      }
    }
  }
`

// const SUBSC = gql`
//   subscription newMessage($conversation: ID!) {
//     newMessage(conversation: $conversation) {
//       id
//       createdOn

//       sender {
//         id
//       }

//       message {
//         ... on MessageText {
//           text
//         }
//       }
//     }
//   }
// `

export const SendMessageMutation = gql`
  mutation SendMessage($conversation: ID!, $message: String!) {
    sendMessage(conversation: $conversation, message: { type: TEXT, text: $message }) {
      id
      createdOn
      sender {
        id
      }

      message {
        ... on MessageText {
          text
        }
      }
    }
  }
`

export const ConversationQuery = gql`
  query Conversation($id: ID!) {
    conversation(id: $id) {
      id
      members {
        id
      }

      messages(limit: 100) {
        items {
          id
          createdOn

          sender {
            id
          }

          message {
            ... on MessageText {
              text
            }
          }
        }
      }
    }
  }
`
