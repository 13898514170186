import React, { useState, useContext, useCallback } from 'react'
import styled from 'styled-components'
import Auth from '@aws-amplify/auth'
import { withRouter } from 'react-router'

import { Link } from '../../components/Base/Link'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'

import { Session } from '../../context'
import { History } from '../../system/history'

const Form = styled.form`
  width: 100%;
`

const ButtonLinks = styled.div`
  text-align: left;
`

const SubmitButton = styled(Button)`
  margin: 15px 0 15px 0;
`

const Wrapper = styled(Container)`
  padding-top: 50px;
`

const BaseInput = styled(TextField).attrs({
  fullWidth: true,
  required: true,
  variant: 'outlined',
  margin: 'normal',
})``

const StyledDivider = styled(Divider)`
  width: 68px;
  margin: 10px auto 0 auto;
  background-color: #666;
`

export const Register = withRouter(({ match }) => {
  const { setSession } = useContext(Session)

  const [isLoading, setLoading] = useState(false)
  const [username, setUsername] = useState('')
  const [invite, setInvite] = useState(match.params['invite'])
  const [password, setPassword] = useState('$')
  const [hasError, setError] = useState(false)
  // const [confirmation, setConfirmation] = useState('')

  // const [confirmed, setConfirmed] = useState(false)
  // const [submitted, setSubmitted] = useState(false)

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()

      setLoading(true)

      try {
        await Auth.signUp({
          username,
          password,
          attributes: {
            'custom:invite': invite,
          },
        })

        await Auth.signIn(username, password)

        setSession({ username })
        History.push('/dashboard')
      } catch (e) {
        setError(true)
        setLoading(false)
        // setSubmitted(false)
        // setConfirmed(false)
      }
    },
    [username, password, invite, setSession],
  )

  // const onConfirmation = useCallback(
  //   async (event) => {
  //     event.preventDefault()

  //     setLoading(true)

  //     try {
  //       await Auth.confirmSignUp(username, confirmation)
  //       await Auth.signIn(username, password)

  //       setLoading(false)
  //       setConfirmed(true)
  //     } catch (e) {
  //       alert(e.message)

  //       setLoading(false)
  //       setConfirmed(false)
  //     }
  //   },
  //   [username, confirmation, password],
  // )

  return (
    <Wrapper maxWidth="xs">
      <CssBaseline />

      {/* {submitted && !confirmed && (
        <Form noValidate onSubmit={onConfirmation}>
          <BaseInput
            label="Confirmation Code"
            onChange={(e) => {
              setConfirmation(e.target.value)
              setError(false)
            }}
            disabled={isLoading}
            error={hasError}
          />
          <SubmitButton type="submit" fullWidth variant="contained" color="primary">
            Confirm
          </SubmitButton>
        </Form>
      )} */}

      <Form noValidate onSubmit={onSubmit}>
        <BaseInput
          label="Invite Code"
          defaultValue={invite}
          onChange={(e) => {
            setInvite(e.target.value)
            setError(false)
          }}
          disabled={isLoading}
          error={hasError}
        />

        <StyledDivider variant="middle" />

        <BaseInput
          label="Username"
          autoComplete="username"
          onChange={(e) => {
            setUsername(e.target.value)
            setError(false)
          }}
          disabled={isLoading}
          error={hasError}
        />
        <BaseInput
          label="Password"
          type="password"
          autoComplete="current-password"
          onChange={(e) => {
            setPassword(e.target.value)
            setError(false)
          }}
          disabled={isLoading}
          error={hasError}
        />

        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          Create Account
        </SubmitButton>

        <ButtonLinks>
          <Link to="/auth">Already have an account? Sign In!</Link>
        </ButtonLinks>
      </Form>
    </Wrapper>
  )
})
