import { createContext, Dispatch, SetStateAction } from 'react'

export interface Session {
  username: string
}

export interface SessionContext {
  session?: Session
  setSession: Dispatch<SetStateAction<Session | undefined>>
}

export const Session = createContext<SessionContext>({ setSession: () => null })
