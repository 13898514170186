import Auth from '@aws-amplify/auth'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { createAuthLink, AUTH_TYPE } from 'aws-appsync-auth-link'

import config from './config'

export const client = new ApolloClient({
  link: ApolloLink.from([
    createAuthLink({
      url: config.GRAPHQL_URL,
      region: config.REGION,
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () =>
          (await Auth.currentSession()).getAccessToken().getJwtToken(),
      },
    }),
    createHttpLink({ uri: config.GRAPHQL_URL }),
  ]),
  cache: new InMemoryCache(),
})
