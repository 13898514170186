import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router'
import { HelmetProvider } from 'react-helmet-async'
import { createGlobalStyle } from 'styled-components'
import Amplify from '@aws-amplify/core'
import { ApolloProvider } from '@apollo/react-common'
import { StylesProvider } from '@material-ui/core'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { History } from './system/history'
import config from './system/config'
import { client } from './system/api'

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.REGION,
    userPoolId: config.USER_POOL_ID,
    identityPoolId: config.IDENTITY_POOL_ID,
    userPoolWebClientId: config.APP_CLIENT_ID,
  },
})

const GlobalStyles = createGlobalStyle`
  html, body, #root {
    margin: 0;
    height: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  body {
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router history={History}>
      <GlobalStyles />

      <HelmetProvider>
        <StylesProvider injectFirst>
          <App />
        </StylesProvider>
      </HelmetProvider>
    </Router>
  </ApolloProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
