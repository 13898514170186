import React, { useContext } from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'

import Card from '@material-ui/core/Card'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'

import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Session } from '../../context'

import { UserByID } from '../../queries'

const EditButton = styled(Button)`
  float: right;
  position: relative;
  top: -10px;
  margin-right: -10px;
`

export default withRouter(({ match }) => {
  const { session } = useContext(Session)

  const username = match.params['username']

  const { data, error, loading } = useQuery(UserByID, {
    variables: { id: username },
  })

  if (loading) {
    return <p>Loading …</p>
  }

  if (error) {
    console.log(error)
    return <p>Error …</p>
  }

  return (
    <Paper elevation={1}>
      <Card variant="outlined">
        <CardContent>
          {session!.username === username ? (
            <EditButton size="small" color="primary">
              Edit
            </EditButton>
          ) : null}

          {data.user.avatar && <Avatar src={data.user.avatar.url} />}

          <Typography color="primary" gutterBottom>
            {data.user.name || `@${data.user.id}`}
            {data.user.name && (
              <Typography color="textSecondary" gutterBottom component="span">
                {' '}
                - @{data.user.id}
              </Typography>
            )}
          </Typography>

          <Typography variant="body2" component="p">
            Created at {data.user.createdAt}
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  )
})
