import React, { useState, useContext, useCallback } from 'react'
import styled from 'styled-components'
import Auth from '@aws-amplify/auth'

import { Link } from '../../components/Base/Link'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'

import { Session } from '../../context'
import { History } from '../../system/history'

const Form = styled.form`
  width: 100%;
`

const ButtonLinks = styled.div`
  text-align: right;
`

const SubmitButton = styled(Button)`
  margin: 15px 0 15px 0;
`

const Wrapper = styled(Container)`
  padding-top: 50px;
`

const BaseInput = styled(TextField).attrs({
  fullWidth: true,
  required: true,
  variant: 'outlined',
  margin: 'normal',
})``

export const Login = () => {
  const { setSession } = useContext(Session)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [hasError, setError] = useState(false)

  const onSuccess = useCallback(
    (username) => {
      setSession({ username })

      History.push('/dashboard')
    },
    [setSession],
  )

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      setError(false)
      setLoading(true)

      try {
        const user = await Auth.signIn(username, password)

        setLoading(false)
        onSuccess(user.username)
      } catch (e) {
        setLoading(false)
        setError(true)
      }
    },
    [username, password, onSuccess],
  )

  return (
    <Wrapper maxWidth="xs">
      <CssBaseline />
      <Form noValidate onSubmit={onSubmit}>
        <BaseInput
          label="Username"
          autoComplete="username"
          onChange={(e) => {
            setUsername(e.target.value)
            setError(false)
          }}
          disabled={isLoading}
          error={hasError}
          autoFocus
        />
        <BaseInput
          label="Password"
          type="password"
          autoComplete="current-password"
          onChange={(e) => {
            setPassword(e.target.value)
            setError(false)
          }}
          disabled={isLoading}
          error={hasError}
        />

        <SubmitButton
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
          fullWidth
        >
          Sign In
        </SubmitButton>

        <ButtonLinks>
          <Link to="/auth/register">{"Don't have an account? Sign Up!"}</Link>
        </ButtonLinks>
      </Form>
    </Wrapper>
  )
}
